import React, {useState, useEffect} from 'react';
import { TextInputMask, datetimeMask, celPhoneMask } from 'react-masked-text';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';

import { MdMenu } from "react-icons/md";

import { Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import topo from '../../topo.png';
import topo_desktop from '../../assets/tipo_desktop.png';

export default function Cadastrar() {
    const [sucesso, setSucesso] = useState(false);
    const [nome, setNome] = useState('');
    // const { register, handleSubmit } = useForm();
    // const { handleSubmit, control } = useForm();
    const onSubmit = (data) => {
      alert(JSON.stringify(data));
    };

    const { register, handleSubmit, reset, control, formState,
        formState: { isSubmitSuccessful }, } = useForm({
            defaultValues: { nome: "", telefone: "", dtnascimento: "" }
        // resolver: yupResolver(schema),
        // validationSchema: fieldsValidationSchema
      });


      function handleSalvar(data) { 
        // setIsLoading(true);       
         
        console.log('Salvar doador');
        console.log(data);
        setSucesso(true);
        


        // if (item?.id > 0) {
        //     console.log(item);
        //     const config = {
        //         method: 'post',
        //         url: `https://appsesites.com.br/ApiPedidos.php?action=insert_clientes`,
        //         headers: {
        //           'Content-Type': 'multipart/form-data'
        //         },
        //         timeout:1000,
        //         data: {
        //           'id': item.id,
        //           'nome': data.empresa,
        //           'endereco': data.endereco,
        //           'cidade': data.cidade,
        //           'estado': data.estado,
        //           'cnpj': data.cnpj,
        //           'inscestadual': data.inscestadual,
        //           'action': 'insert_clientes'
        //         }
        //       };
          
        //       const req = axios(config).then((res) => { 
                
                
        //         navigation.navigate('Clientes', {
        //             modalVisible: false
        //         })
        //         // navigation.navigate('Clientes', {
        //         //     itemId: route.params.itemId.id
        //         // })
        //        });
        // }

        // if(typeof route !== "undefined" && !item?.id){
        //     console.log("Não é undefined");
        //     if(route.params.itemId.id){
        //         const config = {
        //             method: 'post',
        //             url: `https://appsesites.com.br/ApiPedidos.php?action=insert_clientes`,
        //             headers: {
        //               'Content-Type': 'multipart/form-data'
        //             },
        //             timeout:1000,
        //             data: {
        //               'id': route.params.itemId.id,
        //               'nome': data.empresa,
        //               'endereco': data.endereco,
        //               'cidade': data.cidade,
        //               'estado': data.estado,
        //               'cnpj': data.cnpj,
        //               'inscestadual': data.inscestadual,
        //               'action': 'insert_clientes'
        //             }
        //           };
              
        //           const req = axios(config).then((res) => { 
        //             navigation.navigate('Clientes', {
        //                 itemId: route.params.itemId.id
        //             })
        //            });
        //         // ApiCliente.inserir_cliente(route.params.itemId.id, data.empresa, data.endereco, data.cidade, data.estado, data.cnpj, data.inscestadual, data.pagamento);

        //         // navigation.navigate('Clientes', {
        //         //     itemId: route.params.itemId.id
        //         // })
        //     }
        // }
        // else if(!item?.id){
            // console.log("É undefined");
            console.log("salvar");
            // reset({
            //             nome: "",
            //             telefone:"",
            //             dtnascimento:""
            //           })
            const config = {
                method: 'post',
                // url local
                // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=insert_doador`,
                url: `ApiDoador.php?action=insert_doador`,
                headers: {
                  'Content-Type': 'multipart/form-data'
                },
                timeout:1000,
                data: {
                  'id': null,
                  'nome': data.nome,
                  'telefone': data.telefone,
                  'dtnascimento': data.dtnascimento,                  
                  'action': 'insert_doador'
                }
              };
          
              const req = axios(config).then((res) => { 
                window.location.href = '/';
                reset({
                    nome: "",
                    telefone:"",
                    dtnascimento:""
                  })
      
              
               });
            // ApiCliente.inserir_cliente(null, data.empresa, data.endereco, data.cidade, data.estado, data.cnpj, data.inscestadual, data.pagamento);
        
            // reset({
            //   empresa: "",
            //   endereco:""
            // })

            // navigation.navigate('Clientes', {
            //     modalVisible: false
            // })
            
        // }
        
        // navigation.navigate('Produtos');
        // setModalVisible(!modalVisible);
        //     useEffect(() => {
        //         const focusHandler = navigation.addListener('focus', () => {
        //             Alert.alert('Refreshed');
        //         });
        //         return focusHandler;
        //     }, [navigation]);

        // navigation.navigate('Clientes');  
        
        
    }

    function Menu() {
      return (
        <Dropdown style={{width:150, marginTop:20}}>
          <Dropdown.Toggle style={{backgroundColor:'black', marginLeft:'5%', width:'40%', height:50}}  id="">
            <MdMenu />
          {/* <IoMenuOutline style={{}} /> */}
          </Dropdown.Toggle>
    
          <Dropdown.Menu style={{backgroundColor:'black'}}>
            <Dropdown.Item> <Link style={{all: 'unset', color:'white'}} to="/cadastrar">Cadastrar</Link></Dropdown.Item>
            <Dropdown.Divider color='white' />
            <Dropdown.Item> <Link style={{all: 'unset', color:'white'}} to="/doadores">Doadores</Link></Dropdown.Item>
            {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
          </Dropdown.Menu>
        </Dropdown>
        
      );
    }

    const [matches, setMatches] = useState(
      window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
      window
      .matchMedia("(min-width: 768px)")
      .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    // setNome('');

    // useEffect(() => {
        
    //         reset({
    //             nome: "",
    //             telefone:"",
    //             dtnascimento:""
    //           })
        
    //   }, [sucesso])
  
    return (

      matches ?

      <header className="App-header-cadastrar">
      
      <div style={{
        // backgroundColor:'blue',
        position:'fixed',
        backgroundImage:`url(${topo_desktop})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        top:0,
        height:'300px',
        width:'100%'
      }} class="header">

<Menu />
</div>


  <h1 style={{marginTop:'17%'}}>Cadastro de Doadores</h1>

  <form onSubmit={handleSubmit(data => handleSalvar(data))}>
      <Controller
        control={control}
        name="nome"
        render={({ field: { onChange, onBlur, value, ref } }) => (
           <div>
            <label><h5>Nome</h5></label>
          <input
          
            style={{
                backgroundColor: '#FFF',
                width: '100%',
                
                marginBottom: 15,
                color: '#222',
                fontSize: 14,
                borderRadius: 7,
                padding: 10
            }}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            
          />
          </div>
        )}
        defaultValue=""
      />

<div style={{display:'flex', flexDirection:'row', width:'100%'}}>
    <Controller
        control={control}
        name="telefone"
        render={({ field: { onChange, onBlur, value, ref } }) => (
           <div style={{display:'flex',flexDirection:'column'}}>
            <label><h5>Telefone</h5></label>
          <TextInputMask
          mask={celPhoneMask()}
            style={{
                backgroundColor: '#FFF',
                width: '80%',
                
                marginBottom: 15,
                color: '#222',
                fontSize: 14,
                borderRadius: 7,
                padding: 10
            }}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
          />
          </div>
        )}
        defaultValue=""
      />

<Controller
        control={control}
        name="dtnascimento"
        render={({ field: { onChange, onBlur, value, ref } }) => (
           <div style={{display:'flex',flexDirection:'column'}}>
            <label><h5>Data de Nascimento</h5></label>
            <TextInputMask
            mask={datetimeMask()} 
            style={{
                backgroundColor: '#FFF',
                width: '100%',
                
                marginBottom: 15,
                color: '#222',
                fontSize: 14,
                borderRadius: 7,
                padding: 10
            }}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
          />
          </div>
        )}
        defaultValue=""
      />
      </div>


      
      <input style={{
        backgroundColor: '#59BFFF',
        width: '90%',
        height: 45,    
        marginBottom:5,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 7,
        alignSelf:'center',
        width: '45%' }} type="submit" />
    </form>

  
      </header>

      :

        <header className="App-header-cadastrar">
      <div style={{
        // backgroundColor:'blue',
        position:'fixed',
        backgroundImage:`url(${topo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        top:0,
        height:'100px',
        width:'100%'
      }} class="header">

        <Menu />
    
  </div>

  <h1 style={{marginTop:'25%'}}>Cadastro de Doadores</h1>

  <form onSubmit={handleSubmit(data => handleSalvar(data))}>
      <Controller
        control={control}
        name="nome"
        render={({ field: { onChange, onBlur, value, ref } }) => (
           <div>
            <label>Nome</label>
          <input
          
            style={{
                backgroundColor: '#FFF',
                width: '90%',
                
                marginBottom: 15,
                color: '#222',
                fontSize: 22,
                borderRadius: 7,
                padding: 10
            }}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
            
          />
          </div>
        )}
        defaultValue=""
      />

    <Controller
        control={control}
        name="telefone"
        render={({ field: { onChange, onBlur, value, ref } }) => (
           <div style={{display:'flex',flexDirection:'column'}}>
            <label>Telefone</label>
          <TextInputMask
          mask={celPhoneMask()}
            style={{
                backgroundColor: '#FFF',
                width: '70%',
                
                marginBottom: 15,
                color: '#222',
                fontSize: 22,
                borderRadius: 7,
                padding: 10
            }}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
          />
          </div>
        )}
        defaultValue=""
      />

<Controller
        control={control}
        name="dtnascimento"
        render={({ field: { onChange, onBlur, value, ref } }) => (
           <div style={{display:'flex',flexDirection:'column'}}>
            <label>Data de Nascimento</label>
            <TextInputMask
            mask={datetimeMask()} 
            style={{
                backgroundColor: '#FFF',
                width: '70%',
                
                marginBottom: 15,
                color: '#222',
                fontSize: 22,
                borderRadius: 7,
                padding: 10
            }}
            onChange={onChange}
            onBlur={onBlur}
            selected={value}
          />
          </div>
        )}
        defaultValue=""
      />


      
      <input style={{
        backgroundColor: '#59BFFF',
        width: '90%',
        height: 45,    
        marginBottom:5,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 7,
        alignSelf:'center',
        width: '45%' }} type="submit" />
    </form>

  
      </header>
      
    );
  }

