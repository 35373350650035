import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Dropdown from 'react-bootstrap/Dropdown';
import { IoMenuOutline } from "react-icons/io5";
import { MdMenu } from "react-icons/md";
import { FaCheckDouble } from "react-icons/fa";

import { Link } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

import { TesteFont } from './Styles';
import topo from '../../topo.png';
import topo_desktop from '../../assets/tipo_desktop.png';
import './Doadores.css';

function App() {

  const { innerWidth: width, innerHeight: height } = window;

    const [selectedImage, setSelectedImage] = useState(null);

    const [telefone, setTelefone] = useState();

    const [search, setSearch] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [masterData, setMasterData] = useState([]);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  )

  useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
  }, []);

  console.log(width);

  function executa() {

    try{

    const config = {
      method: 'post',
      //endereço local
      // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=getdoadores`,
      url: `ApiDoador.php?action=getdoadores`,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      timeout:10000,
      data: {
        'id':'',
        'action': 'getdoadores'
      }
    };

    const req = axios(config).then((res) => {
      var temp = [];

      console.log(res);
      for (let i = 0; i < res.data.length; ++i)
        temp.push(res.data[i]);
     
      setFilteredData(temp);
      setMasterData(temp);
      

    }).catch((err) => {
      executa();
    });
  }catch (error) {
    if (axios.isCancel(error)) {
      console.log('A solicitação foi cancelada devido a timeout');
      executa();
      // Aqui você pode fazer algo, como tentar novamente a consulta
      // ou exibir uma mensagem de erro para o usuário
    } else {
      // Lidar com outros erros de rede ou do servidor
      console.error('Erro:', error.message);
      throw error;
    }

  }
}


function handleSetarEnviado(iddoacao) {  
   
      const config = {
          method: 'post',
          // url local
          // url: `https://doadordesangue.appsesites.com.br/ApiDoador.php?action=doador_enviado`,
          url: `ApiDoador.php?action=insert_doador`,
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          timeout:5000,
          data: {
            'id': iddoacao,                              
            'action': 'doador_enviado'
          }
        };
    
        const req = axios(config).then((res) => {                  
        
         });        
  
}

  const searchFilter = (text) => {
    if (text) {
    
      const newData = masterData.filter(
        function (item) {
            console.log('teste');
            console.log(item);
          if (item.nome) {
              // console.log(item.nome.toUpperCase());
              // console.log(text.toUpperCase());
              
            const itemData = item.nome.toUpperCase();
            const textData = text.toUpperCase();
            console.log(itemData.indexOf(textData) > -1);
            return itemData.indexOf(textData) > -1;
          }
      });
      setFilteredData(newData);
      setSearch(text);
    } else {
      setFilteredData(masterData);
      setSearch(text);
    }
  };

  useEffect(() => {

    executa();
  }, []);


    const handleImageUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file);
      }
    };

    const handleShare = async () => {
        if (navigator.share) {
          try {
            await navigator.share({
              title: 'Shared Image',
              text: 'Check out this image!',
              url: 'https://files.tecnoblog.net/wp-content/uploads/2022/04/google_capa-5.jpg'
            });
          } catch (error) {
            console.error('Error sharing:', error);
          }
        } else {
          alert('Web Share API não suportada no seu navegador.');
        }
      };

    const array_doadores = [
        {
            nome: 'José de Souza',
            telefone: '5522999384187'
        },
        {
            nome: 'Marcos Andtônio Carvalho'
        },
        {
            nome: 'Maria de Souza'
        },
        {
            nome: 'Etelvina Maria da Silva'
        },
        {
            nome: 'Carlos Eduardo Gonçalves'
        },
        {
            nome: 'Lucas Valença'
        },
        {
            nome: 'Felipe Silva'
        },
        {
            nome: 'Rafaela Moreira Vasques'
        }
    ]

    const [result, setResult] = useState('');

  const numbers = [1, 2, 3, 4, 5];
const ListItems = () => {numbers.map((number) =>
  {return <li key={number.toString()}>
    {number}
  </li>}
)};

const shareData = {
    title: "MDN",
    text: "Learn web development on MDN!",
    // url: "https://tibmacae.org.br",
    url: "https://wa.me/5522999384187/?text=teste" + "%20 teste"
    // url: "https://whatsapp://send?text=teste" + "%20 teste",
  };

  const isChrome = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    console.log(userAgent);
    return /chrome/.test(userAgent) && !/edge|edg|opr|brave/.test(userAgent);
  };

  const shareNovo = async() => {
    if (navigator.share) {
        try {
          await navigator.share({
            title: 'Shared Image',
            text: 'Check out this image!',
            url: '../src/assets/logo192.png'
          });
        } catch (error) {
          console.error('Error sharing:', error);
        }
      } else {
        alert('Web Share API não suportada no seu navegador.');
      }
  }

function share (telefone, iddoacao) {

    handleSetarEnviado(iddoacao);
// console.log('teste');
    //   if (!isChrome()) {
    //     alert('Este recurso só está disponível no Google Chrome.');
    //     return;
    //   }
// console.log(telefone);
if(telefone !== undefined){
    console.log(telefone);
    try {
        // await navigator.share(shareData);

        //Configuracao das marcacoes do texto
        //%20 - espaco
        //%0A - pula linha

        var whatsappUrl = "https://wa.me/+5522999384187/?text=teste" + "%20 teste";
        // var testeurl = "https://wa.me/"+telefone+"/?text=*Obrigado%20doador*!!!,%0ASua%20doação%20salva%20vidas%20linha%0ATerceira%20linha" + encodeURIComponent("https://tnics.com.br/img/Logo%203.png");
        var testeurl = "https://wa.me/"+telefone+"/?text=*Obrigado%20doador*!!!,%0A%0ASua%20doação%20salva%20vidas.";

        // shareNovo();
        // Abrir o link
        window.open(testeurl, '_blank');
        setResult("MDN shared successfully");
      } catch (err) {
        setResult(`Error: ${err}`);
      }
    }
}

const ShareButton = () => {
    const imageUrl = 'https://doadordesangue.appsesites.com.br/imagem.html'; // URL da imagem hospedada
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent('Confira esta imagem incrível: ' + imageUrl)}`;
  
    return (
      <a href={whatsappUrl} target="_blank" rel="noopener noreferrer">
        Compartilhar no WhatsApp
      </a>
    );
  };

  const ShareButton2 = () => {
    const handleShare = async() => {
      if (navigator.share) {
        navigator.share({
          title: 'Confira esta imagem!',
          text: 'Veja esta imagem incrível que encontrei!',
          files: [
            new File(
              [await fetch('https://tnics.com.br/img/cameras.jpg').then(r => r.blob())],
              'cameras.jpg',
              { type: 'image/jpg' }
            )
          ],
        })
        .then(() => console.log('Compartilhado com sucesso!'))
        .catch((error) => console.error('Erro ao compartilhar:', error));
      } else {
        alert('Compartilhamento não suportado neste navegador.');
      }
    };
  
    return (
      <button onClick={handleShare}>
        Compartilhar no WhatsApp
      </button>
    );
  };

  

  function ButtonDoador ({dados}) {
    const[telefoneDoador, setTelefoneDoador] = useState();

    console.log(dados);

    const dias = dados.dias == 0 ? 'hoje.' : 'há ' + dados.dias + ' dias.';


    const className = dados.dias > 3 ? 'maior' : 'menor';
    

    return (
      matches ?
        <div style={{backgroundColor: 'white', marginBottom: '4%', width:'98%', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        <button className={className} onClick={() => share('55'+ dados.telefone.replace('(', '').replace(')', '').replace('-','').replace(' ',''), dados.iddoacao)} style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont>{dados.nome + ' - Doação feita ' + dias}</TesteFont>{dados.enviado == 'S' ? <FaCheckDouble style={{color: 'blue'}} /> : null}</button>
        
        </div>
        :
        <div style={{backgroundColor: 'white', marginBottom: '4%', width:'98%', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
        <button className={className} onClick={() => share('55'+ dados.telefone.replace('(', '').replace(')', '').replace('-','').replace(' ',''), dados.iddoacao)} style={{width:'100%', height:50, justifyContent:'space-between', alignItems:'center', textAlign:'center', display:'flex', flexDirection:'row',  borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont style={{fontSize:'16px'}}>{dados.nome + ' - Doação feita ' + dias}</TesteFont>{dados.enviado == 'S' ? <FaCheckDouble style={{color:'blue'}} /> : null}</button>
        </div>
    )
    


  }

  function Menu() {
    return (
      <Dropdown style={{width:150, marginTop: matches ? 20 : 10, marginBottom:20}}>
        <Dropdown.Toggle style={{backgroundColor:'black', marginLeft:'5%', width:'40%', height:50}}  id="">
          <MdMenu />
        {/* <IoMenuOutline style={{}} /> */}
        </Dropdown.Toggle>
  
        <Dropdown.Menu style={{backgroundColor:'black'}}>
          <Dropdown.Item> <Link style={{all: 'unset', color:'white'}} to="/cadastrar">Cadastrar</Link></Dropdown.Item>
          <Dropdown.Divider color='white' />
          <Dropdown.Item> <Link style={{all: 'unset', color:'white'}} to="/doadores">Doadores</Link></Dropdown.Item>
          {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
        </Dropdown.Menu>
      </Dropdown>
      
    );
  }

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // A aba voltou a ficar visível
        // Atualize o estado ou execute alguma ação
        console.log('A aba está visível novamente');
        executa();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  

  return (
    // <div className="App">
    matches ?  
      <header className="App-header">
      <div style={{backgroundColor:'#282c34', justifyContent:'flex-start', alignItems:'flex-start', alignSelf:'flex-start', display:'flex', flexDirection:'column', position:'fixed', width:'100%', top:0, height:'350px'}}>
      <div style={{
        // backgroundColor:'blue',
        alignSelf:'flex-start',
        backgroundImage:`url(${topo_desktop})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        top:0,
        height:'200px',
        width:'100%'
      }} class="header">

<Menu />
</div>

<div style={{backgroundColor:'#282c34', width:'100%', textAlign:'center', alignSelf:'center', justifyContent:'center', alignItems:'center', alignContent:'center', display:'flex', flexDirection:'column'}}>
<h1 style={{}}>Doadores</h1>


{/* <div >
       {matches && (<h1>Big Screen</h1>)}
       {!matches && (<h3>Small Screen</h3>)}
     </div> */}

<input
               style={{
                width: '33%',
                
                // padding: 20,
                // height: 40,   
                fontSize:22,
                height:50,
                borderRadius:30,
                flexDirection: 'column',
                alignSelf:'center',
                // padding:15,         
                backgroundColor: '#FFFFFF',
                marginBottom:'3%'
               }}
               placeholder="Buscar doador"        
               // placeholderTextColor="#FFFFFF"
              //  value={search}
               onInput={(text) => searchFilter(text.target.value)}
            ></input>
            </div>
    
  </div>

  {/* <img src={topo} alt="Logo" /> */}
{/* <div style={{width:'95%', justifyContent:'center', textAlign:'center', position:'fixed', top:0, backgroundColor:'#282c34'}}> */}
  {/* <h1 style={{paddingTop:'20%'}}>Doadores</h1> */}

        {/* <ShareButton2 /> */}

      {/* <h1>Upload and Share Image on WhatsApp</h1>
      <input type="file" accept="image/*" onChange={handleImageUpload} /> */}

      {/* {selectedImage && (
        <div>
          <h2>Uploaded Image:</h2>
          <img src={selectedImage} alt="Uploaded" style={{ width: '300px' }} />
          <button onClick={handleShare}>Share on WhatsApp</button>
        </div>
      )} */}
{/* <input
               style={{
                width: '95%',
                
                // padding: 20,
                // height: 40,   
                fontSize:22,
                height:50,
                borderRadius:30,
                flexDirection: 'column',
                alignSelf:'center',
                // padding:15,         
                backgroundColor: '#FFFFFF',
                marginBottom:'10%'
               }}
               placeholder="Buscar clientes"        
               // placeholderTextColor="#FFFFFF"
               value={search}
               onChangeText={(text) => searchFilter(text)}
            ></input> */}
            {/* </div> */}
            <div style={{width:'55%', marginTop:'30%', alignSelf:'center', display:'flex', marginLeft:'3%', flexDirection:'column', alignContent:'flex-start', alignItems:'flex-start'}}>
        {filteredData.map((rowData, rowIndex) => (
          
            
            <ButtonDoador dados={rowData} />
            
            // <div style={{backgroundColor: 'white', marginBottom: '1%', marginTop:'2%', width:'98%', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
            // <button onClick={setTelefone(rowData.telefone)} style={{width:'100%', borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont>{rowData.nome}</TesteFont></button>
            // </div>

        ))

        }
</div>
        {/* <TesteFont>{result}</TesteFont> */}
        
        {/* <TesteFont>
          Edit <code>src/App.js</code> and save to reload.
        </TesteFont> */}
        
          {/* Learn React */}
        
        {/* {numbers.map((number) =>
  <li key={number.toString()}>
    {number}
  </li>
)} */}

      </header>
      : 


      <header className="App-header">
        
      <div style={{
        // backgroundColor:'blue',
        position:'fixed',
        backgroundImage:`url(${topo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        top:0,
        height:'100px',
        width:'100%'
      }} class="header">

<Menu />


<div style={{backgroundColor:'#282c34', textAlign:'center'}}>
<h1 style={{}}>Doadores</h1>


{/* <div >
       {matches && (<h1>Big Screen</h1>)}
       {!matches && (<h3>Small Screen</h3>)}
     </div> */}

<input
               style={{
                width: '75%',
                
                // padding: 20,
                // height: 40,   
                fontSize:22,
                height:50,
                borderRadius:30,
                flexDirection: 'column',
                alignSelf:'center',
                // padding:15,         
                backgroundColor: '#FFFFFF',
                marginBottom:'3%'
               }}
               placeholder="Buscar doador"        
               // placeholderTextColor="#FFFFFF"
              //  value={search}
               onInput={(text) => searchFilter(text.target.value)}
            ></input>
            </div>
    
  </div>

  {/* <img src={topo} alt="Logo" /> */}
{/* <div style={{width:'95%', justifyContent:'center', textAlign:'center', position:'fixed', top:0, backgroundColor:'#282c34'}}> */}
  {/* <h1 style={{paddingTop:'20%'}}>Doadores</h1> */}

        {/* <ShareButton2 /> */}

      {/* <h1>Upload and Share Image on WhatsApp</h1>
      <input type="file" accept="image/*" onChange={handleImageUpload} /> */}

      {/* {selectedImage && (
        <div>
          <h2>Uploaded Image:</h2>
          <img src={selectedImage} alt="Uploaded" style={{ width: '300px' }} />
          <button onClick={handleShare}>Share on WhatsApp</button>
        </div>
      )} */}
{/* <input
               style={{
                width: '95%',
                
                // padding: 20,
                // height: 40,   
                fontSize:22,
                height:50,
                borderRadius:30,
                flexDirection: 'column',
                alignSelf:'center',
                // padding:15,         
                backgroundColor: '#FFFFFF',
                marginBottom:'10%'
               }}
               placeholder="Buscar clientes"        
               // placeholderTextColor="#FFFFFF"
               value={search}
               onChangeText={(text) => searchFilter(text)}
            ></input> */}
            {/* </div> */}
            <div style={{width:'95%', marginTop:'65%', display:'flex', marginLeft:'3%', flexDirection:'column', alignContent:'flex-start', alignItems:'flex-start'}}>
        {filteredData.map((rowData, rowIndex) => (
          
            
            <ButtonDoador dados={rowData} />
            
            // <div style={{backgroundColor: 'white', marginBottom: '1%', marginTop:'2%', width:'98%', borderTopRightRadius:20, borderBottomLeftRadius:20}}>
            // <button onClick={setTelefone(rowData.telefone)} style={{width:'100%', borderTopRightRadius:20, borderBottomLeftRadius:20}}   ><TesteFont>{rowData.nome}</TesteFont></button>
            // </div>

        ))

        }
</div>
        {/* <TesteFont>{result}</TesteFont> */}
        
        {/* <TesteFont>
          Edit <code>src/App.js</code> and save to reload.
        </TesteFont> */}
        
          {/* Learn React */}
        
        {/* {numbers.map((number) =>
  <li key={number.toString()}>
    {number}
  </li>
)} */}

      </header>
    // </div>
  );
}

export default App;
